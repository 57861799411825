<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <b-row class="justify-content-center">
            <b-col md="6">
              <div class="clearfix">
                <h1 class="float-left display-3 mr-4">404</h1>
                <h4 class="pt-3">{{$t('message.404_title')}}</h4>
                <p class="text-muted">{{$t('message.404_description')}}</p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404'
}
</script>
